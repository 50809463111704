const CVRallyUXR = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=15f-c9YyYTKmxa29uRisY1pTNdTQoIboB&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVRallyUXR


