const CVFirecrawl = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1R2Khtlh2RZ0Nd-FrdpAy-_amFZl9D4VMuFcPobPHffg&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVFirecrawl





