const CVSilurian = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1cmVOinH9rFk3lQh50jBM8PbTA1tteTEjXElgXlWtn7I&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVSilurian


