
const CVContalink = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1xlbLyrkNDVHgd0BwybhjEMc8wVq6R3z-rCq8sxO5Vls&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVContalink

