const CVLumenalta = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1hmG7NAZjrVzvH4fKd-VhtZ87zVGNQTOwk_3NSn1Qqw0&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVLumenalta






