
const CVShuttle = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1nK5nDhXmUUmuvGOIW5fNm-7SyXmJ4EBKxdCPEQFer38&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVShuttle

