const CVJabu = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1B7L0TiADjArKDDX5XaEmlV2wXQe1c83tZCXLQCcHnrk&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVJabu



